@mixin document-body {
  html, body {
    min-height:100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size:$global__fontSize;
    font-family: $global__fontFamily;
    font-weight:$global__weight;
    line-height:$global__lineHeight;
  }
}
