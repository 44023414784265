// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group typography
////

$map__headings: h1, h2, h3, h4, h5;

/// Default font family of a heading
/// @type string
/// @see {variable} $global__fontFamily
/// @since 0.0.1-alpha
$headings--fontFamily: $global__fontFamily !default;

/// Default padding of a heading
/// @type string
/// @since 0.0.1-alpha
$headings--padding:0 !default;

/// Default margin of a heading
/// @type string
/// @since 0.0.1-alpha
$headings--margin:1rem 0 !default;

/// Default text-transform of a heading
/// @type string
/// @since 0.0.1-alpha
$headings--textTransform: uppercase !default;
$headings__h4--textTransform: none !default;
$headings__h5--textTransform: none !default;

/// Default font weight of a heading
/// @type string
/// @see {color} $global__weight--light
/// @since 0.0.1-alpha
$headings--weight: $global__weight--heavy !default;
$headings__h5--weight: $global__weight--bold !default;

/// Default color of a heading
/// @type string
/// @see {variable} $color__black
/// @since 0.0.1-alpha
$headings--color: inherit !default;

/// Default bottom border of a heading
/// @type string
/// @since 0.0.1-alpha
$headings--borderBottom: none !default;

/// Responsive font sizes of an <h1>
/// @type string
/// @since 0.0.4-beta
$headings__h1--fontSize: 48px !default;

/// Responsive font sizes of an <h2>/// @type string
/// @since 0.0.4-beta
$headings__h2--fontSize: 36px !default;

/// Responsive font sizes of an <h3>
/// @type string
/// @since 0.0.4-beta
$headings__h3--fontSize: 28px !default;

/// Responsive font sizes of an <h4>
/// @type string
/// @since 0.0.4-beta
$headings__h4--fontSize: 18px !default;

/// Responsive font sizes of an <h5>
/// @type string
/// @since 0.0.4-beta
$headings__h5--fontSize: 16px !default;


@mixin typography-headings {
  #{$map__headings} {
    font-family: $headings--fontFamily;

    padding: $headings--padding;
    margin: $headings--margin;

    text-transform: $headings--textTransform;
    font-weight:$headings--weight;
    color:$headings--color;

    border-bottom:$headings--borderBottom;
  }

  h1 {
    @include font-size($headings__h1--fontSize);
  }

  h2 {
    @include font-size($headings__h2--fontSize);
  }

  h3 {
    @include font-size($headings__h3--fontSize);
  }

  h4 {
    @include font-size($headings__h4--fontSize);
    text-transform: $headings__h4--textTransform;
  }
  h5 {
    @include font-size($headings__h5--fontSize);
    font-weight:$headings__h5--weight;
    text-transform: $headings__h5--textTransform;
  }
}
