// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group embedding
////

@mixin embedding-img {
  img {
    display: inline-block;
    vertical-align: middle;
    max-width:100%;
    height:auto;
    -ms-interpolation-mode: bicubic;
  }
}
