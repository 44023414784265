// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group typography
////

/// Default background color of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button--backgroundColor: $color__primary !default;

/// Default background color on hover of a button
/// @type string
/// @see {Colors} $color__primary--light
/// @since 0.0.1-alpha
$button-hover--backgroundColor: $color__primary--light !default;

/// Default text decoration of a button
/// @type string
/// @since 0.0.1-alpha
$button--textDecoration: none !default;

/// Default text-transform of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button--textTransform: uppercase !default;

/// Default font weight of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button--weight: $global__weight--light !default;

/// Default color of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button--color: $color__black !default;

/// Default hover color of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button-hover--color: $color__black !default;

/// Default color of a button outline
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button__outline--color: $color__primary !default;

/// Default border of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button--border: 1px solid $color__primary--dark !default;

/// Default border radius of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button--borderRadius: 0 !default;

/// Default padding of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button--padding: 10px !default;

/// Default margin of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button--margin: 0 !default;

/// Default transition of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button--transition: unset !default;

/// Default hover transition of a button
/// @type string
/// @see {Colors} $color__primary
/// @since 0.0.1-alpha
$button-hover--transition: unset !default;

@mixin typography-button {
  .button {
    display:inline-block;
    background-color: $button--backgroundColor;

    text-decoration: $button--textDecoration;
    text-transform:  $button--textTransform;
    font-weight: $button--weight;
    color: $button--color;

    border: $button--border;
    border-radius: $button--borderRadius;

    padding: $button--padding;
    margin: $button--margin;

    transition: $button--transition;

    &:hover {
      background-color: $button-hover--backgroundColor;
      color: $button-hover--color;
      transition: $button-hover--transition;
    }

    @include modifier('outline') {
      background-color: transparent;
      color:$button__outline--color;

      &:hover {
        background-color: $button-hover--backgroundColor;
        color: $button-hover--color;
      }
    }
  }
}
