// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group typography
////


/// Default font size of a <small>
/// @type string
/// @since 0.0.1-alpha
$small--fontSize: .8em !default;

/// Default color state of a <small>
/// @type string
/// @see {Colors} $color__gray--dark
/// @since 0.0.1-alpha
$small--fontColor: $color__gray--dark !default;

@mixin typography-small {
  small {
    line-height:0;
    font-size:$small--fontSize;
    color:$small--fontColor;
  }
}
