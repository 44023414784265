// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group typography
////

@mixin typography-b {
  // Strong Text
  strong, b {
    font-weight: $global__weight--bold;
    line-height: inherit;
  }
}
