// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

/// Unicode Character for a heavy X
/// @type string
/// @group characters
/// @since 0.0.1-alpha
$char__x: "\2716" !default;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Color Variables
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

/// Base Color Array
/// @type array
/// @group color
/// @since 0.0.4-beta
$colors: () !default;

/// Brand Color Array
/// @type array
/// @group color
/// @since 0.0.4-beta
$colors__brand: (
  "primary": #8BC34A,
  "accent": #D76D52,
  "secondary": #81388D,
  "foreground": #191919,
  "background": #e9e9e9,
  "success":#597151,
  "warning":#957234,
  "error":#b2312f,
) !default;

/// These were deprecated in 0.0.4-beta, and will be removed in 0.0.5-beta
@import "deprecation/004/_settings.colors";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Global variables
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

/// Global font-family
/// @type string
/// @group typography
/// @since 0.0.1-alpha
$global__fontFamily: "system" !default;

/// Global font-size
/// @type string
/// @group typography
/// @since 0.0.1-alpha
$global__fontSize: 100% !default;

/// Global line height
/// @type string
/// @group typography
/// @since 0.0.4-beta
$global__lineHeight: 1.6 !default;

/// Global Max container width
/// @type string
/// @group typography
/// @since 0.0.3-alpha
$global__maxWidth: 1200px !default;

/// Global thin font weight
/// @type string
/// @group typography
/// @since 0.0.1-alpha
$global__weight--thin: 100 !default;

/// Global light font weight
/// @type string
/// @group typography
/// @since 0.0.1-alpha
$global__weight--light: 300 !default;

/// Global font weight
/// @type string
/// @group typography
/// @since 0.0.1-alpha
$global__weight: 400 !default;

/// Global medium font weight
/// @type string
/// @group typography
/// @since 0.0.1-alpha
$global__weight--medium: 500 !default;

/// Global bold font weight
/// @type string
/// @group typography
/// @since 0.0.1-alpha
$global__weight--bold: 700 !default;

/// Global heavy font weight
/// @type string
/// @group typography
/// @since 0.0.1-alpha
$global__weight--heavy: 900 !default;
