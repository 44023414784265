@mixin -uic-typography {

  h1 {
    font-style:italic;
    letter-spacing: normal;
    line-height: 1.38em;

    strong,u {
      background:linear-gradient(to top, rgba(0, 0, 0, 0) 10%, color('primary',200) 10%, color('primary',200) 35%, rgba(0, 0, 0, 0) 35%);
      font-weight: $headings--weight;
      text-decoration: none;
    }

    & + hr {
      border: 0;
      border-bottom: solid 2px color('primary',200);
      margin: 1.25rem 0;
      width: 20%;
    }
  }

  h2 {
    font-style:italic;
    letter-spacing: 0.54px;
    line-height: 1.4em;

    & + hr {
      border: 0;
      border-bottom: solid 2px color('primary',200);
      margin: 1.25rem 0;
      width: 20%;
    }
  }

  h3 {
    font-style:italic;
    letter-spacing: 0;
    line-height: 1.38em;

    & + hr {
      border: 0;
      border-bottom: solid 2px color('primary',200);
      margin: 1.25rem 0;
      width: 20%;
    }
  }

  h4 {
    color:color('primary');
    letter-spacing: 0.35px;
    line-height: 2.21em;
  }

  h5 {
    font-weight:700;
    letter-spacing: normal;
    line-height: 1.72em;

    strong,u {
      font-style:italic;
      font-weight: 900;
      text-decoration: none;
    }
  }

  p {
    line-height: 2.22em;
  }


  .button {
    @include box-shadow();
    font-size:font-size(16px);
    position:relative;
    width: 100%;
    text-align: center;
    @include breakpoint(ipad) {
      width: auto;
      text-align: left;
    }

    &::after {
      content: ' ';
      width: 0px;
      height:0px;
      border-bottom: .5em solid $button--color;
      border-left: .5em solid transparent;
      bottom: 1em;
      right:1em;
      position: absolute;
    }
  }
}
