// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group typography
////

/// Default margin of a list
/// @type string
/// @since 0.0.1-alpha
$list--margin: 1rem 0 1rem 0 !default;

/// Default padding of a list
/// @type string
/// @since 0.0.1-alpha
$list--padding: 0 0 0 2rem !default;

/// Default position of a list style
/// @type string
/// @since 0.0.1-alpha
/// @prop inside - Flush left with the text
/// @prop outside - Extends to the left of the text.
$list--stylePosition: outside;

/// Responsive line-height of a <ul> and <ol>
/// @type map
/// @see {function} fluidType
/// @prop windowWidth - window width without a unit
/// @prop line-height - line-height without a unit (normally em)
/// @since 0.0.1-alpha
$list--lineHeight: (
  1200: 1.375,
  640: 1.125
) !default;


/// Default style of an <ol>
/// @type string
/// @since 0.0.1-alpha
/// @prop decimal - Decimal numbers beginning with 1.
/// @prop decimal-leading-zero - Decimal numbers padded by inital zero
/// @prop lower-roman - Lowercase roman numerals
/// @prop upper-roman - Uppercase roman numerals
/// @prop lower-alpha - Lowercase ASCII letters
/// @prop upper-alpha - Uppercase ASCII letters
$list__ol--listStyle: decimal;

/// Default margin of an <ol>
/// @type boolean | string
/// @since 0.0.1-alpha
$list__ol--margin: false;

/// Default margin of a <ul>
/// @type boolean | string
/// @since 0.0.1-alpha
$list__ul--margin: false;

/// Default style of an <ul>
/// @type string
/// @prop disc - A filled circle (default)
/// @prop circle - A hollow circle
/// @prop square - A filled square
/// @since 0.0.1-alpha
$list__ul--listStyle: square;

@mixin typography-list {
  ul, ol {
    margin:$list--margin;
    padding: $list--padding;
    list-style-position:$list--stylePosition;
    line-height: fluidType($list--lineHeight, em);

    & & {
      margin-bottom: 0;
    }
  }

  li {
    font-size:inherit;
    display:list-item;
  }

  ol {
    list-style: $list__ol--listStyle $list--stylePosition;
    @if $list__ol--margin {
      margin: $list__ol--margin;
    }
  }

  ul {
    list-style: $list__ul--listStyle $list--stylePosition;
    @if $list__ul--margin {
      margin: $list__ul--margin;
    }
  }
}
