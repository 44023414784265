@import 'theme';
@import 'typography';

// Global Init
@include -lv-font-face;
@include -lv-normalize;
@include -lv-layout($centered: true, $structure: true, $grid: true);
@include -lv-document;
@include -lv-embedding;
@include -lv-typography;

html, body {
  color: color('foreground');
  background-color: color('background', 100);
}

@include -uic-typography;
