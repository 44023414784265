// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group typography
////

@mixin typography-em {
  em {
    font-style: italic;
    line-height: inherit;
  }
}
