$grid__options--cells: 'center', 'end', 'start' !default;
$grid__options--column: 1,2,3,4,5,6,7,8,9,10,11,12 !default;
$grid__options--gutter: 1% !default;
$grid__options--row: "around", "between", "center", "end", "flush", "start", "static" !default;
$grid__options--sizes: "lg", "md", "sm", "xs" !default;

$grid__breakpoints: (
  "lg": "screen", // > 1200
  "md": "screen and (max-width:1200px)", // 1024 - 1200
  "sm": "screen and (max-width:800px)", // 640 - 1024
  "xs": "screen and (max-width:500px)", // 320 - 640
  // "mobile": "screen and (max-width:320px)"
);

@mixin -lv-layout-grid {
  .row {
    display: grid;
    grid-gap:1rem;
    grid-template-columns: repeat(12, 1fr);

  // Grid
    @each $key, $break in $grid__breakpoints {
      @media #{$break} {
        @each $size in $grid__options--sizes {
          @if $key == $size {
            @each $column in $grid__options--column {
              .col-#{$size}-#{$column} {
                grid-column-end:span $column;
              }
            }
          }
        }
      }
    }
  // End Grid

    @media screen and (max-width:360px) {
      @each $size in $grid__options--sizes {
        @each $column in $grid__options--column {
          .col-#{$size}-#{$column} {
            grid-column-end:span 12;
          }
        }
      }
    }
  }
}

