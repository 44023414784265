@function color($color, $weight: 500) {
  @if map-has-key($colors, $color) {
    @if $weight == 500 {
      @return map-get($colors, $color);
    }

    @if map-get($colors, "#{$color}--#{$weight}") {
      @return map-get($colors, "#{$color}--#{$weight}");
    }

    @if $weight > 500 {
      @return darken(map-get($colors, $color), (($weight - 500)/10));
    } @else {
      @return lighten(map-get($colors, $color), ((500 - $weight)/10));
    }
  } @else {
    @warn "Key: #{$color} was not found.";
    @return #FF69B4
  }
}