// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group typography
////

/// Default font family of a paragraph
/// @type string
/// @see $global__fontFamily;
/// @since 0.0.1-alpha
$paragraph--fontFamily: $global__fontFamily !default;

/// Default padding of a paragraph
/// @type string
/// @since 0.0.1-alpha
$paragraph--padding:0 !default;

/// Default margin of a paragraph
/// @type string
/// @since 0.0.1-alpha
$paragraph--margin:1rem 0 !default;

/// Default text-transform of a paragraph
/// @type string
/// @since 0.0.1-alpha
$paragraph--textTransform: none !default;

/// Default font weight of a paragraph
/// @type string
/// @see {Colors} $global__weight
/// @since 0.0.1-alpha
$paragraph--weight: $global__weight !default;

/// Default color of a paragraph
/// @type string
/// @see {Colors} $color__black
/// @since 0.0.4-beta
$paragraph--color: inherit !default;

/// Responsive font sizes of a <p>
/// @type map
/// @see {function} fluidType
/// @see {variable} $global__fontSize
/// @prop windowWidth - window width without a unit
/// @prop font-size - font-size without a unit
/// @since 0.0.1-alpha
$paragraph--fontSize: $global__fontSize !default;

/// Responsive line-height of a <p>
/// @type map
/// @see {function} fluidType
/// @prop windowWidth - window width without a unit
/// @prop line-height - line-height without a unit (normally em)
/// @since 0.0.1-alpha
$paragraph--lineHeight: (
  1200: 1.475,
  640: 1.325
) !default;

@mixin typography-p {
  p {
    font-family: $paragraph--fontFamily;
    font-size: 1em;
    // line-height: fluidType($paragraph--lineHeight, em);

    padding: $paragraph--padding;
    margin: $paragraph--margin;

    text-transform: $paragraph--textTransform;
    font-weight:$paragraph--weight;
    color:$paragraph--color;
  }
}
