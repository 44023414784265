/// Success Color - Background - Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__error: #ecc8c5 !default;

/// Success Color - Border Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__error--light: #bd8181 !default;

/// Success Color - Font Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__error--dark: #b2312f !default;

/// Success Color - Background - Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__warning: #f8f3d6 !default;

/// Success Color - Border Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__warning--light: #d7ce86 !default;

/// Success Color - Font Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__warning--dark: #957234 !default;

/// Success Color - Background - Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__success: #def2d6 !default;

/// Success Color - Border Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__success--light: #b0cca5 !default;

/// Success Color - Font Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__success--dark: #597151 !default;

/// Primary Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__primary: #8BC34A !default;

/// Light version of the Primary Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__primary--light: #BDE194 !default;

/// Dark version of the Primary Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__primary--dark: #4F7F18 !default;

/// Secondary Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__secondary: #D76D52 !default;

/// Light version of the Secondary Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__secondary--light: #EBAB9B !default;

/// Dark version of the Secondary Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__secondary--dark: #8C321B !default;

/// Tertiary Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__tertiary: #81388D !default;

/// Light version of the Tertiary Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__tertiary--light: #BC84C6 !default;

/// Dark version of the Tertiary Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__tertiary--dark: #51125C !default;

/// Primary White Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__white: #fff !default;

/// Primary Black Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__black: #000 !default;

/// Primary Gray Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__gray: #cacaca !default;

/// Light version of the Gray Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__gray--light: #e6e6e6 !default;

/// Dark version of the Gray Color
/// @type string
/// @group color
/// @since 0.0.1-alpha
/// @deprecated in 0.0.4-beta swaped to a generated format
$color__gray--dark: #8a8a8a !default;
