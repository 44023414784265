// Leverage by Brolik
// leverage.com
// Licensed under MIT Open Source

////
/// @group typography
////

/// Default margins for a blockquote
/// @type string
/// @since 0.0.1-alpha
$blockquote--margin: 1rem 0 !default;

/// Default padding for a blockquote
/// @type string
/// @since 0.0.1-alpha
$blockquote--padding: 0.5rem 0.5rem !default;

/// Default border for a blockquote. Can be false, or a border declaration
/// @type boolean | string
/// @since 0.0.1-alpha
$blockquote--border: false !default;

/// Default color for a block quote
/// @type boolean | string
/// @see {variable} $color__black;
/// @since 0.0.1-alpha
$blockquote--color: $color__black !default;

/// Default background-color for a block quote
/// @type boolean | string
/// @since 0.0.1-alpha
$blockquote--backgroundColor: false !default;

/// Default left border for a blockquote. Can be false, or a border declaration
/// @type boolean | string
/// @see {variable} $color__primary
/// @since 0.0.1-alpha
$blockquote--borderLeft: 5px solid $color__primary;

/// Default right border for a blockquote. Can be false, or a border declaration
/// @type boolean | string
/// @since 0.0.1-alpha
$blockquote--borderRight: false;

/// Responsive font sizes of a <p>
/// @type map
/// @see {function} fluidType
/// @prop windowWidth - window width without a unit
/// @prop font-size - font-size without a unit
/// @since 0.0.1-alpha
$blockquote--fontSize: false !default;

/// Responsive line-height of a <p>
/// @type map
/// @see {function} fluidType
/// @prop windowWidth - window width without a unit
/// @prop line-height - line-height without a unit (normally em)
/// @since 0.0.1-alpha
$blockquote--lineHeight: false !default;

@mixin typography-blockquote {
  blockquote {
    margin:$blockquote--margin;

    @if $blockquote--backgroundColor {
      background-color: $blockquote--backgroundColor;
    }

    // If the full border variable is set.
    @if $blockquote--border {
      border: $blockquote--border;
    }

    // If the left border variable is set
    @if $blockquote--borderLeft and not $blockquote--border {
      border-left: $blockquote--borderLeft;
    }

    // If the right boarder variable is set.
    @if $blockquote--borderRight and not $blockquote--border {
      border-right: $blockquote--borderRight;
    }

    p {
      color:$blockquote--color;
      padding:$blockquote--padding;
      @if $blockquote--fontSize {
        font-size: fluidType($blockquote--fontSize, px);
      }

      @if $blockquote--lineHeight {
        line-height: fluidType($blockquote--lineHeight, em);
      }
    }
  }
}
